<template>
  <div>
    <b-card-body>
      <div class="d-flex justify-content-between flex-wrap">

        <!-- Sorting  -->
        <b-form-group
          label="Sort"
          label-size="sm"
          label-align-sm="left"
          label-cols-sm="1"
          label-for="sortBySelect"
          label-class="mt-50 mr-50 pl-0"
          class="mb-0 text-nowrap"
          style="min-width: 20rem"
        >
          <b-input-group
            size="sm"
          >
            <b-form-select
              id="sortBySelect"
              v-model="sortBy"
              :options="sortOptions"
            >
              <template #first>
                <option value="">
                  none
                </option>
              </template>
            </b-form-select>
            <b-form-select
              v-model="sortDesc"
              size="sm"
              :disabled="!sortBy"
            >
              <option :value="false">
                Asc
              </option>
              <option :value="true">
                Desc
              </option>
            </b-form-select>
          </b-input-group>
        </b-form-group>
        <!-- ./Sorting  -->

        <!-- Search -->
        <b-form-group
          label="Search"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          label-for="filterInput"
          label-class="mt-50"
          class="mb-0"
          style="width: 30rem"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              placeholder="Type to Search"
            />
            <b-input-group-append>
              <b-button
                :disabled="!filter"
                @click="filter = ''"
              >
                Clear
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
        <!-- ./Search -->
      </div>
    </b-card-body>

    <b-table
      responsive
      striped
      hover
      show-empty
      class="position-relative"
      :items="items"
      :fields="fields"
      :per-page="perPage"
      :current-page="currentPage"
      :filter="filter"
      :filter-included-fields="filterOn"
      @filtered="onFiltered"
      @row-clicked="openTest"
    >
      <!-- Default row style -->
      <template #cell()="data">
        {{ data.value }}
      </template>
      <!-- ./Default row style -->

      <!-- Test details -->
      <template #cell(name)="data">
        <div v-html="data.value" />
      </template>
      <!-- ./Test details -->

      <!-- Actions -->
      <!--<template #cell(actions)="data">-->
      <!--  <b-button-->
      <!--    variant="flat-secondary"-->
      <!--    size="sm"-->
      <!--    class="text-nowrap"-->
      <!--    @click="openTest(data.item)"-->
      <!--  >-->
      <!--    <feather-icon-->
      <!--      icon="ExternalLinkIcon"-->
      <!--      class="mr-50"-->
      <!--    />-->
      <!--    <span>Open</span>-->
      <!--  </b-button>-->
      <!--</template>-->
      <!-- ./Action -->
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
      <!-- Page length -->
      <b-form-group
        label="Per Page"
        label-cols="6"
        label-align="left"
        label-size="sm"
        label-class="mt-50"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          size="sm"
          inline
          :options="pageOptions"
        />
      </b-form-group>
      <!-- ./Page length -->

      <!-- Pagination -->
      <div>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
      <!-- ./Pagination -->
    </b-card-body>
  </div>
</template>

<script>
import { BPagination, BTable } from 'bootstrap-vue'

export default {
  name: 'TestsTable',
  components: {
    BTable,
    BPagination,
  },
  props: {
    items: {
      required: true,
      type: Array,
    },
    fields: {
      required: false,
      type: Array,
      default: () => [
        { key: 'ref_id', label: 'ID', sortable: true },
        { key: 'priority', label: 'Priority', sortable: true },
        { key: 'latest_result', label: 'Last Result', sortable: true },
        { key: 'name', label: 'Test', sortable: true },
        // { key: 'actions', label: '', sortable: false },
      ],
    },
  },
  data: () => ({
    perPage: 5,
    pageOptions: [3, 5, 10],
    totalRows: 1,
    currentPage: 1,
    sortBy: '',
    sortDesc: false,
    sortDirection: 'asc',
    filter: null,
    filterOn: [],
  }),
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    openTest(test) {
      this.$router.push(
        {
          name: 'model_test_focus',
          params: { testId: test.id },
        },
      )
    },
  },
}
</script>

<style lang="scss">
@import 'src/@core/scss/vue/libs/vue-select.scss';
</style>
