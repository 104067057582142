<template>
  <section>
    <b-row>
      <!-- Basic Properties -->
      <b-col cols="2">
        <b-card>
          <div>
            <h5 class="text-capitalize mb-75">
              ID
            </h5>
            <b-card-text>{{ requirement.properties.display_id }}</b-card-text>
          </div>
          <div class="mt-2">
            <h5 class="text-capitalize mb-75">
              Priority
            </h5>
            <b-card-text>{{ requirement.properties.priority }}</b-card-text>
          </div>
          <div class="mt-2">
            <h5 class="text-capitalize mb-75">
              Specification
            </h5>
            <b-card-text>{{ requirement.properties.specification }}</b-card-text>
          </div>
          <div class="mt-2">
            <h5 class="text-capitalize mb-75">
              Section
            </h5>
            <b-card-text>{{ requirement.properties.section }}</b-card-text>
          </div>
          <div class="mt-2">
            <h5 class="text-capitalize mb-75">
              Created
            </h5>
            <b-card-text>{{ requirement.properties.created }}</b-card-text>
          </div>
          <div class="mt-2">
            <h5 class="text-capitalize mb-75">
              Updated
            </h5>
            <b-card-text>{{ requirement.properties.updated }}</b-card-text>
          </div>
        </b-card>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          class="w-100"
          variant="outline-danger"
          @click="$bvModal.show('delete-requirement-modal')"
        >
          <span>
            <feather-icon
              icon="TrashIcon"
              class="mr-25 text-danger"
            />
            Delete
          </span>
        </b-button>
      </b-col>
      <!-- ./Basic Properties -->

      <!-- Requirement Text -->
      <b-col>
        <b-card>
          <b-card-header class="p-0 pb-50">
            <b-card-title class="select-all">
              {{ requirement.properties.display_id }}
            </b-card-title>
            <b-card-sub-title>
              <span class="font-small-1 select-all">{{ requirement.properties.id }}</span>
            </b-card-sub-title>
          </b-card-header>

          <div v-if="isEditingRequirementText">
            <tip-tap-editor
              v-model="requirement.properties.object_text"
            />
            <b-button
              variant="outline-success"
              size="sm"
              class="mt-2"
              @click="saveChanges"
            >
              Save Changes
            </b-button>
          </div>
          <div v-else>
            <b-card-text
              v-if="requirement.properties.object_text !== '<p></p>'"
              class="mt-2"
              @click="isEditingRequirementText = !isEditingRequirementText"
              v-html="requirement.properties.object_text"
            />
            <b-card-text
              v-else
              @click="isEditingRequirementText = !isEditingRequirementText"
            >
              <span>This requirement is empty...</span>
            </b-card-text>
          </div>

          <!-- comments -->
          <!--<div-->
          <!--  v-for="(comment,ind) in data.detailedComments"-->
          <!--  :key="ind"-->
          <!--  class="d-flex align-items-start mb-1"-->
          <!--&gt;-->
          <!--  <b-avatar-->
          <!--    :src="comment.avatar"-->
          <!--    size="34"-->
          <!--    class="mt-25 mr-75"-->
          <!--  />-->
          <!--  <div class="profile-user-info w-100">-->
          <!--    <div class="d-flex align-items-center justify-content-between">-->
          <!--      <h6 class="mb-0">-->
          <!--        {{ comment.username }}-->
          <!--      </h6>-->
          <!--      <b-link class="text-body">-->
          <!--        <feather-icon-->
          <!--          icon="HeartIcon"-->
          <!--          size="18"-->
          <!--          class="mr-25"-->
          <!--          :class="comment.youLiked ? 'profile-likes' :'profile-icon'"-->
          <!--        />-->
          <!--        <span class="text-muted align-middle">{{ comment.commentsLikes }}</span>-->
          <!--      </b-link>-->
          <!--    </div>-->
          <!--    <small>{{ comment.comment }}</small>-->
          <!--  </div>-->
          <!--</div>-->
          <!--/ comments -->

          <!-- comment box -->
          <!--<b-form-group>-->
          <!--  <b-form-textarea-->
          <!--    rows="3"-->
          <!--    placeholder="Add comment"-->
          <!--  />-->
          <!--</b-form-group>-->
          <!--/ comment box -->

          <!--<b-button-->
          <!--  v-ripple.400="'rgba(255, 255, 255, 0.15)'"-->
          <!--  size="sm"-->
          <!--  variant="primary"-->
          <!--&gt;-->
          <!--  Post Comment-->
          <!--</b-button>-->
        </b-card>
      </b-col>
      <!-- ./Requirement Text -->
    </b-row>
    <requirement-delete :is-focus="true" />
  </section>
</template>

<script>
import { mapState } from 'vuex'
import Ripple from 'vue-ripple-directive'
import TipTapEditor from '@/components/Forms/TipTapEditor/TipTapEditor.vue'
import RequirementDelete from '@/views/RequirementsTableLegacy/modals/Delete.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'RequirementGeneral',
  directives: {
    Ripple,
  },
  components: {
    TipTapEditor,
    RequirementDelete,
  },
  data: () => ({
    isEditingRequirementText: false,
  }),
  computed: {
    ...mapState({
      requirement: state => state.requirements.selected_requirement,
    }),
  },
  methods: {
    saveChanges() {
      this.isEditingRequirementText = !this.isEditingRequirementText
      this.$store.dispatch('requirements/patchRequirement', { object_text: this.requirement.properties.object_text })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Requirement Updated',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        })
    },
  },
}
</script>
