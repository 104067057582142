<template>
  <b-tabs justified>
    <b-tab title="Issue Details" active>
      <b-form-group
        id="issue-group-name"
        label="Issue:"
        label-for="issue-name"
      >
        <tip-tap-editor
          id="issue-name"
          v-model="name"
        />
      </b-form-group>

      <b-form-group
        id="issue-group-desc"
        label="Long Description:"
        label-for="issue-desc"
      >
        <tip-tap-editor
          id="issue-desc"
          v-model="description"
          min-height="8"
          max-height="8"
        />
        <!-- FIXME tip-tap-editor - min-height: 8rem -->
      </b-form-group>

      <b-form-group id="issue-group-classification" label="Classification:" label-for="issue-classification">
        <b-form-select
          id="issue-classification"
          v-model="classification"
          :options="issueClassifications"
          required
        />
      </b-form-group>

      <b-form-group id="issue-group-severity" label="Severity:" label-for="issue-severity">
        <b-form-select
          id="issue-severity"
          v-model="severity"
          :options="issueSeverities"
          required
        />
      </b-form-group>

      <b-form-group id="issue-group-status" label="Status:" label-for="issue-status">
        <b-form-select
          id="issue-status"
          v-model="status"
          :options="issueStatuses"
          required
        />
      </b-form-group>

      <b-row class="mt-2">
        <b-col>
          <b-button variant="outline-success" @click="submitFunction">
            Save changes
          </b-button>
        </b-col>
      </b-row>
    </b-tab>

    <!--<b-tab>-->
    <!--  <template slot="title">-->
    <!--    <b-badge variant="light-primary mr-50">-->
    <!--      {{ commentLength }}-->
    <!--    </b-badge>-->
    <!--    Comments-->
    <!--  </template>-->
    <!--  <CommentCard-->
    <!--    :key="commentKey"-->
    <!--    :parent_id="selected_issue.id"-->
    <!--    :parent_label="'Issue'"-->
    <!--  />-->
    <!--</b-tab>-->

    <b-tab title="Issue Context">
      <h3 class="text-right">
        Associated Requirements
      </h3>
      <hr>
      <b-button
        v-b-modal.associator-generic
        v-b-hover="hoverHandler1"
        class="mb-1"
        :variant="editButton"
        block
      >
        <feather-icon
          icon="Edit2Icon"
          class="text-success mr-25"
          style="margin-bottom: 0.1rem"
        />
        Edit Requirement Associations
      </b-button>
      <div class="mb-1">
        <div v-for="req in selected_issue.requirements" :key="req.id" class="mb-1">
          <issue-requirement-card :requirement="req" />
        </div>
        <div v-if="!selected_issue.requirements || selected_issue.requirements && selected_issue.requirements.length === 0" class="mb-2">
          <em>No associated requirements...</em>
        </div>
      </div>

      <h3 class="text-right">
        Associated Entities
      </h3>
      <hr>
      <b-button
        v-b-modal.create-associator-generic
        v-b-hover="hoverHandler2"
        class="mb-1"
        :variant="editButton2"
        block
      >
        <feather-icon
          icon="Edit2Icon"
          class="text-success mr-25"
          style="margin-bottom: 0.1rem"
        />
        Edit Entity Associations
      </b-button>
      <div class="mb-1">
        <div v-for="comp in selected_issue.components" :key="comp.id">
          <issue-entity-card :entity="comp" />
        </div>
        <div v-if="selected_issue.components && selected_issue.components.length === 0" class="mb-2">
          <em>No associated entities...</em>
        </div>
      </div>

      <h3 class="text-right">
        Associated Behaviours
      </h3>
      <hr>
      <div class="mb-1">
        <div v-for="bn in selected_issue.behaviours" :key="bn.id">
          <issue-behaviour-card :behaviour="bn" />
        </div>
        <div v-if="selected_issue.behaviours && selected_issue.behaviours.length === 0" class="mb-2">
          <em>No associated behaviours...</em>
        </div>
      </div>

      <!--<pre>{{ selected_issue }}</pre>-->
    </b-tab>

    <issue-requirement-allocate />
    <issue-component-allocate />
    <associator-generic
      name="Requirements"
      :all-items="allItems"
      :associated-items="associatedItems"
      @associated="associateRequirements"
    />
    <create-associator-generic
      name="Requirements"
      :all-items="allItems"
      :associated-items="associatedItems"
      @create-new="createRequirement()"
      @associated="associateRequirements"
    />
    <add-requirement-modal :relation-type="null" parent="" parent_rel="sibling" />
  </b-tabs>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import CommentCard from '@/components/Comments/CommentCard.vue'
import IssueRequirementCard from '@/components/Issues/IssueRequirementCard.vue'
import IssueEntityCard from '@/components/Issues/IssueEntityCard.vue'
import IssueBehaviourCard from '@/components/Issues/IssueBehaviourCard.vue'
import IssueRequirementAllocate from '@/components/Issues/Modals/IssueRequirementAllocate.vue'
import IssueComponentAllocate from '@/components/Issues/Modals/IssueComponentAllocate.vue'
import AssociatorGeneric from '@/components/Generic/Associators/AssociatorGeneric.vue'
import CreateAssociatorGeneric from '@/components/Generic/Associators/CreateAssociatorGeneric.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AddRequirementModal from '@/views/RequirementsTableLegacy/modals/Add.vue'
import TipTapEditor from '@/components/Forms/TipTapEditor/TipTapEditor.vue'

export default {
  components: {
    TipTapEditor,
    IssueRequirementCard,
    IssueEntityCard,
    IssueBehaviourCard,
    IssueRequirementAllocate,
    IssueComponentAllocate,
    // CommentCard,
    AssociatorGeneric,
    CreateAssociatorGeneric,
    AddRequirementModal,
  },
  data() {
    return {
      name: '',
      description: '',
      classification: '',
      severity: '',
      status: '',
      editButton: 'outline-secondary',
      editButton2: 'outline-secondary',
      // Comment stuff
      commentLength: 0,
      commentKey: 0,
      // Associator Stuff
      allItems: [],
      associatedItems: [],
    }
  },
  computed: {
    ...mapState('issues', ['selected_issue']),
    ...mapGetters({
      issueClassifications: 'constants/issueClassifications',
      issueSeverities: 'constants/issueSeverities',
      issueStatuses: 'constants/issueStatuses',
    }),
  },
  watch: {
    selected_issue(newVal) {
      this.fillFields(newVal)
    },
  },
  mounted() {
    this.fillFields(this.selected_issue)
  },
  methods: {
    fillFields(val) {
      this.name = val.name
      this.description = val.description
      this.classification = val.classification
      this.severity = val.severity
      this.status = val.status

      const data = {
        id: this.selected_issue.id,
        label: 'Issue',
      }
      // this.$store.dispatch('comments/getEntityComments', data)
      //   .then(data => {
      //     this.commentLength = data.length
      //     this.commentKey += 1
      //   })
      this.associatedRequirementsForAssociator()
    },
    submitFunction() {
      const payload = {
        data: {
          name: this.name,
          description: this.description,
          classification: this.classification,
          severity: this.severity,
          status: this.status,
        },
        id: this.selected_issue.id,
      }

      this.$store.dispatch('issues/updateIssue', payload)
        .then(() => {
          this.$emit('updated')
        })
    },
    hoverHandler1(handler) {
      if (handler) this.editButton = 'outline-success'
      else this.editButton = 'outline-secondary'
    },
    hoverHandler2(handler) {
      if (handler) this.editButton2 = 'outline-success'
      else this.editButton2 = 'outline-secondary'
    },
    associatedRequirementsForAssociator() {
      this.associatedItems = this.selected_issue.requirements
        .map(x => ({
          value: {
            id: x.id,
            toSortBy: x.display_id,
          },
          text: `${x.display_id}. ${x.object_text.replace(/<\/?[^>]+(>|$)/g, '')}`,
        }))
      this.allRequirementsForAssociator()
    },
    allRequirementsForAssociator() {
      const params = { model: this.$store.state.model.id }
      this.$http
        .get('/api/v2/requirements/get_requirements_simple', { params })
        .then(({ data }) => {
          this.allItems = data.map(item => ({
            value: {
              id: item.id,
              toSortBy: item.display_id,
            },
            text: `${item.display_id}. ${item.text}`,
          }))
          // Remove already associated elements from the allItems array
          this.associatedItems.forEach((elementA, indexA) => {
            this.allItems.forEach((elementB, indexB) => {
              if (elementA.value.id === elementB.value.id) {
                this.allItems.splice(indexB, 1)
              }
            })
          })
        })
        .catch(r => {
          console.error(`[AssociateRequirementsModal] fetchAll failed - ${r}`)
        })
    },
    associateRequirements(reqs) {
      // console.log('Reqs: ', reqs)
      const selectedId = this.selected_issue.id

      this.$http
        .post('/api/v2/issues/disassociate_all_issue_requirements', { issues: [selectedId] })
        .then(() => {
          this.$http
            .post('/api/v2/issues/associate_issues_and_requirements', {
              issues: [selectedId],
              requirements: reqs,
            })
            .then(() => {
              this.$store.dispatch('issues/selectIssue', selectedId)
              this.allRequirementsForAssociator()
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Requirements associated',
                  text: 'Successfully associated Requirements with the issue.',
                  icon: 'AlertTriangleIcon',
                  variant: 'success',
                },
              })
            })
        })
        .catch(r => {
          console.error(`[AssociateRequirementsModal] submit failed - ${r}`)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to associate Requirements',
              text: `An error occurred when attempting to associate Requirements with the ${this.entityType}.
              Server returned Status ${r}`,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    createRequirement() {
      this.$bvModal.hide('create-associator-generic')
      this.$bvModal.show('add-requirement-modal')
    },
  },
}
</script>

<style lang="scss">
  .dark-layout {
    .hoverCard:hover {
      background-color: #343e5a;
    }
  }

  .hoverCard:hover {
    background-color: #e5e5e5;
    cursor: pointer;
  }
</style>
